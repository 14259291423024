'use client'

import React, { useEffect, useId, useRef } from "react"
import { DrupalNode } from "@/types";
import { useContentFromRoute } from "@/contexts/ContentRouteProvider";
import { ProductFilters } from "./ProductFilters.client";
import { useProducts } from "@/contexts/ProductsProvider";
import { SearchQuestions } from "./SearchQuestions.client";
import { SUResults } from "./SUResults.client";
import { useFeatureFlags } from "@/contexts/FeatureFlagsProvider";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useUserInformation } from "@/contexts/UserProvider";
import { useSearch } from "@/contexts/SearchProvider";

export const SidebarSearch = () => {
  const { openDraw, current, isOpen, draw, close, search, clear, input } = useSearch()
  const observer = useRef<IntersectionObserver>()
  const stickyId = useId()
  const observerMarkId = useId()
  const { products } = useProducts()
  const productFilters = products.filter(p => current?.filters?.products?.includes(p.name))
  const { flags } = useFeatureFlags();
  const user = useUserInformation();

  const { resource } = useContentFromRoute<DrupalNode>()
  if (productFilters.length == 0) {
    productFilters.push(...(resource?.field_products ?? resource?.field_product ? [resource.field_product] : []))
  }

  const isVisible = isOpen && draw == 'search';
  const searchQuery = current?.query

  // If the ref has no value, and the search query is set
  // set the input value to the search query.
  if (input.current && !input.current?.value && searchQuery) {
    input.current.value = searchQuery
  }

  if (input.current && isVisible) {
    input.current.focus()
  }

  // Identify when scrolling induces the sticky header behaviour.
  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]:IntersectionObserverEntry[]) => {
      const element = document.getElementById(stickyId)
      if (element) {
        element.dataset.sticky = entry.intersectionRatio < 1 ? 'true' : 'false'
      }
    }, {
        rootMargin: "100px 0px 0px 0px",
        threshold: [1],
    })

    const element = document.getElementById(observerMarkId)

    if (element) {
      observer.current.observe(element)
      return () => observer.current?.disconnect()
    }
  }, [stickyId, observerMarkId])

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
        // Toggle draw on Ctrl + "/"
        if (e.key == "/" && e.ctrlKey) {
          !isVisible ? openDraw('search') : close()
        }
    }
    document.addEventListener("keyup", onKeyUp);
    return () => {
        document.removeEventListener("keyup", onKeyUp);
    }
  })

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Enter') {
      return
    }
    const query = (e.target as HTMLInputElement).value ?? ''
    if (query.length == 0) {
      return
    }
    search(query, current?.filters, 'searchInput');
  }

  return (
    <>
      <div id={stickyId} className="sticky top-0 bg-white px-6 z-20 pt-6 pb-2 transition-all duration-300 data-[sticky=true]:shadow-lg">
        <ProductFilters />
        <div className="my-4">
          <input 
            onKeyDown={onInputKeyDown}
            onChange={(e) => e.target.value == "" ? clear() : null}
            ref={input}
            autoComplete='off'
            placeholder="Ask a question. Tell us what you're looking for..."
            className="rounded border border-gray-600 p-2 w-full placeholder:text-gray-700"
            />
        </div>
      </div>
      <div id={observerMarkId} />
      { (flags.FLAG_AI_CHAT_ENABLED || user.isLoggedIn) && (
        <>
          <button onClick={() => openDraw('chat')} className="mx-auto w-fit flex items-center gap-2 text-blue-800 hover:underline px-4 py-2 italic text-center text-sm">
            <SparklesIcon role="none" className="w-4" />
            Try Acquia Copilot for AI generated summaries.
          </button>
        </>
      )}
      <div className="p-4 pb-24">
        {!current?.query ? (
            <SearchQuestions />
        ) : (
            <SUResults />
        )}
      </div>
    </>
  )
}
